<!-- Settings -->
<template>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div class="m-bot-15">
          <div class="row">            
            <div class="col-sm-6 col-xs-12 ">    
              <b-form-group class="mb-3 search-bar">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Type to Search"                    
                    auto-complete="nope"
                    @keyup.enter.native="(filter)?fetchData(1):''"
                  ></b-form-input>
                  <span class="search-icon" v-if="filter" id="clear-button">
                    <i class="fas fa-times" @click="filter =''"></i>
                    <b-tooltip target="clear-button" variant="primary">Clear</b-tooltip>
                  </span>
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="(filter)?fetchData(1):''" variant="primary">Search</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-sm-6 col-xs-12 text-right">
              <!-- <b-button class="rounded-0 btn btn-sm btn-primary font-weight-bold text-uppercase" variant="primary" @click="createInvoice()"><i class="fa fa-plus"></i>Add New
              </b-button> -->
              <b-button class="btn-sm btn-filter" v-b-toggle.collapse-1 variant="primary" id="tooltip-button-variant"><i class="fa fa-filter" aria-hidden="true"></i></b-button>
               <b-tooltip target="tooltip-button-variant" variant="primary">Column Filter</b-tooltip>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 select-website">
            <b-collapse id="collapse-1" class="mb-3">
              <b-card>
                <p class="card-text">
                  <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange()">Select All</el-checkbox>
                </p>
                <hr/>
                <el-checkbox-group v-model="checkedColumns" @change="handleCheckedCitiesChange()">
                    <el-checkbox v-for="(column,index) in column_list" :label="column.value" :key="index">{{column.name}}</el-checkbox>
                </el-checkbox-group>
              </b-card>
            </b-collapse>
            <div class="collapse" id="collapseExample">
              <div class="card card-body">
                
              </div>
            </div>
          </div>   
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-table :data="list" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
              <el-table-column align="center" label='Status' prop="id" sortable width="140">
                <template slot-scope="scope">
                    <div v-html="getPaymentStatus(scope.row)"></div>
                </template>
              </el-table-column>
              <el-table-column align="center" label='Due Date' prop="id" sortable width="140">
                <template slot-scope="scope">
                  {{scope.row.payment_due_date | formatDate}}
                </template>
              </el-table-column>
              <el-table-column align="center" label='Invoice #' prop="id" sortable width="140" v-if="showAndHideColumn('invoice_number')">
                <template slot-scope="scope">
                <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewInvoice(scope.row.order_id)">{{(scope.row.invoice_number) ? scope.row.invoice_number : ""}}</a></el-tag>
                </template>
              </el-table-column>
              <el-table-column align="center" label='Order #' prop="id" sortable width="140" v-if="showAndHideColumn('order_number')">
                <template slot-scope="scope">
                <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewOrder(scope.row.order_id)">{{(scope.row.order.order_number) ? scope.row.order.order_number : ""}}</a></el-tag>
                </template>
              </el-table-column>
              <el-table-column  label='Company Name' prop="id" min-width="150" sortable v-if="showAndHideColumn('customer_name')">
                <template slot-scope="scope">
                {{scope.row.order.customer.company_name?scope.row.order.customer.company_name:scope.row.order.customer.first_name+' '+(scope.row.order.customer.last_name == null?"":scope.row.order.customer.last_name)}}
               
                </template>
              </el-table-column>
              <el-table-column align="center" label='Amount Due' prop="id" sortable width="140" v-if="showAndHideColumn('amount_due')">
                <template slot-scope="scope">
                {{(scope.row.order.total) ? '$'+scope.row.order.total : '$0.00'}}
                </template>
              </el-table-column>
              <el-table-column align="center" label='Balance Due' prop="balance_amount" sortable width="140" v-if="showAndHideColumn('balance_amount')">
                <template slot-scope="scope">
                {{(scope.row.balance_amount) ? '$'+scope.row.balance_amount : '$0.00'}}
                </template>
              </el-table-column>
              <!-- <el-table-column align="center" label='Payment' prop="id" sortable width="140">
                <template slot-scope="scope">
                    <span class="btn btn-sm btn-primary btn-rounded" @click="recordPayment(scope.row.id)">Record Payment</span>
                </template>
              </el-table-column> -->
              <el-table-column fixed="right" align="center" label="Actions" width="80">
                <template slot-scope="scope">
                  <el-dropdown @command="handleActions($event,scope.row)" size="medium">
                    <i class="flaticon2-menu-1"></i>
                    <el-dropdown-menu slot="dropdown" class="action-dropdown">
                        <!-- <el-dropdown-item :command="3" ><i class="fa fa-credit-card"></i>Record Payment</el-dropdown-item>
                        <el-dropdown-item :command="4" ><i class="fa fa-credit-card"></i>Card Payment</el-dropdown-item> -->
                        <el-dropdown-item :command="7" v-if="scope.row.invoice_status != 'paid'"><i class="fa fa-credit-card"></i>Pay Now</el-dropdown-item>                        
                        <el-dropdown-item :command="1" ><i class="fa fa-eye"></i>View</el-dropdown-item>
                        <el-dropdown-item :command="3" ><i class="fa fa-edit" v-if="scope.row.order.order_status !='completed' || scope.row.order.order_status !='cancelled' || scope.row.order.order_status !='partially_cancel'"></i>Edit</el-dropdown-item>
                        <el-dropdown-item :command="5" ><i class="fa fa-envelope"></i>Send Mail</el-dropdown-item>
                        <el-dropdown-item :command="6" ><i class="fa fa-download"></i>Download</el-dropdown-item> 
                        <el-dropdown-item :command="2" ><i class="fa fa-trash"></i>Delete</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-pagination v-if="!loading" style="margin-top:15px;"
                background
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange"
                @current-change="fetchData" 
                :page-count="pagination.last_page" 
                :page-sizes="[10, 25, 50, 100]"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page" 
                :total="pagination.total_rows"
                >
            </el-pagination>
          </div>
        </div>
        <v-dialog/>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getAllInvoice,deleteInvoice,downloadPdf} from "@/api/invoice";
//import RecoredPayment from '@/components/Payment/recordpayment';
import CardPayment from '@/components/Payment/creditcard';
import { globalEvents } from '@/utils/globalEvents';
import SendEmail from '@/components/SendEmail';
import payNow from '@/components/Payment/payNow';
export default {
    name: "Invoice",
    data() {
        return {
            loading: false,
            list: [],
            pagination: {
                total_rows: 1,
                current_page: 1,
                last_page: null,
                per_page: 10,
            },
            filter: null,
            clear: null,
            column_list: [{
                    name: "Invoice No.",
                    value: 'invoice_number'
                },
                {
                    name: "Order No.",
                    value: 'order_number'
                },
                {
                    name: "Company Name",
                    value: "customer_name"
                },
                {
                    name: "Amount Due",
                    value: "amount_due"
                },               
                {
                    name: "Balance Due",
                    value: "balance_amount"
                },
                
            ],
            checkAll: false,
            isIndeterminate: true,
            view_type:'invoice',
            checkedColumns: [
                'invoice_number',
                'customer_name',
                'order_number',              
                'amount_due',
                'balance_amount'
            ]
        }
    },
    watch: {
        filter: function(n) {
            if (!n) {
                this.fetchData(1)
            }
        },
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {
                title: "Dashboard",
                route: "/dashboard"
            },
            {
                title: "Invoices"
            },
        ]);

        globalEvents.$on('invoiceAdded', invoice => {
            if (invoice) {
                this.fetchData(1);
            }
        });   
    },
    created() {
        this.fetchData(1);
        
    },
    methods: {
        showAndHideColumn(column_name) {
            return this.$isColumnShow(column_name, this.checkedColumns);
        },
        handleSizeChange(per_page) {
            this.pagination.per_page = per_page;
            this.fetchData(1);
        },
        handleCheckAllChange(val) {
            this.checkedColumns = [];
            if (val) {
                this.column_list.map((column, index) => {
                    this.checkedColumns.push(column.value);
                    console.log(index, this.checkedColumns);
                });
            }
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.column_list.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.column_list.length;
        },
        getPaymentStatus(row){
            let date = new Date(row.payment_due_date);
            var periods = {
                month: 30 * 24 * 60 * 60 * 1000,
                week: 7 * 24 * 60 * 60 * 1000,
                day: 24 * 60 * 60 * 1000,
                hour: 60 * 60 * 1000,
                minute: 60 * 1000
            };
            var diff = Date.now() - date.getTime();
            var status = "";
            var status_msg = "";
            if(row.invoice_status === 'partially_paid'){
                status_msg = '<span class="partially-span el-tag el-tag--mini el-tag--light">Partially Paid</span>';
            }else if(row.invoice_status == 'unpaid' && Math.floor(diff / periods.day) > 0){
                if (diff > periods.month) {
                    status = Math.floor(diff / periods.month) + " month ago";
                } else if (diff > periods.week) {
                    status = Math.floor(diff / periods.week) + " week ago";
                } else if (diff > periods.day) {
                    status = Math.floor(diff / periods.day) + " days ago";
                } /*else if (diff > periods.hour) {
                    status = Math.floor(diff / periods.hour) + " hours ago";
                } else if (diff > periods.minute) {
                    status = Math.floor(diff / periods.minute) + " minutes ago";
                }*/
                status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Over Due</span><div>'+status+'</div>';
            }else if(row.invoice_status === 'paid'){
                status_msg = '<span class="el-tag el-tag--mini el-tag--success">Paid</span>';
                if(row.term_paid_amount > 0){
                    status_msg = '<span class="el-tag el-tag--mini el-tag--success">Term</span>';
                }
            }else if(row.invoice_status === 'unpaid'){
                status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Unpaid</span>';
            }

            return status_msg;
        },
        fetchData(p) {
            this.loading = true
            let params = {
                page: p
            }
            if (this.filter) {
                params.filter = this.filter
            }
            if (this.sort) {
                params.sort = this.sort;
            }
            if (this.pagination.per_page) {
                params.per_page = this.pagination.per_page;
            }
            getAllInvoice(params).then(response => {
                this.list = response.data.data.data
                this.pagination.current_page = response.data.data.current_page
                this.pagination.last_page = response.data.data.last_page
                this.pagination.total_rows = response.data.data.total
                this.loading = false
                //this.$set(this.list, '_showDetails', true)
            })
        },
        createInvoice() {
            this.$router.push({
                path: '/invoice/create'
            })
        },
        handleActions(evt, row) {
            if (evt === 1) {
                this.viewInvoice(row.order_id);
            } else if (evt === 2) {
                this.$bvModal.msgBoxConfirm('Are you sure you want to delete ?', {
                    title: 'Please Confirm',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                }).then(value => {
                    if (value) {
                        this.loading = true;
                        deleteInvoice(row.id).then(response => {
                            if (response.data.status) {
                                this.fetchData(1)
                                this.$showResponse('success', response.data.message);
                            }
                            this.loading = false;
                        })
                    } else {
                        return false;
                    }
                })
            }else if(evt === 3){
              this.$router.push({path: '/invoice/edit/' + row.order.id });
            }else if(evt === 4){
                this.$modal.show(CardPayment, {invoice:row}, {
                draggable: true,
                    transition: 'pop-out',
                    height: 'auto',
                    clickToClose: true,
                    adaptive: true,
                    width: '450px'
                });
            }
              else if (evt === 5){ 
                let customer = row.order.customer;
                let order_id = row.id;
                let type = this.view_type;
            this.$modal.show(SendEmail, {customer, type, order_id}, 
              { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
          } else if (evt === 6){            
            this.loading=true;
            downloadPdf(row.order_id, this.view_type).then(response => {
              //console.log(response.data);
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'invoice.pdf');
              document.body.appendChild(link);
              link.click();
              this.loading = false;
            }).catch(err => {
              console.log(err);
              this.loading = false;
            });
          }else if(evt === 7){
                this.$modal.show(payNow, {invoice:row}, {
                draggable: true,
                    transition: 'pop-out',
                    height: 'auto',
                    clickToClose: true,
                    adaptive: true,
                    width: '450px'
                });
            }
        },
        viewInvoice(invoice_id) {
            this.$router.push({
                path: '/invoice/view/' + invoice_id
            });
        },
        viewOrder(order_id) {
            this.$router.push({
                path: '/order/view/' + order_id
            });
        }
    }
}
</script>
